export const helpers = [
  {
    icon: "bi-envelope",
    text: "propcapitalkft@gmail.com",
  },
  {
    icon: "bi-telephone",
    text: "+36 70 7911902",
  },
  {
    icon: "bi-geo-alt",
    text: "2230 Gyömrő, Bajcsy-Zsilinszky út 6.",
  },
];
