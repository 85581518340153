<template>
  <div class="container mb-5" id="contact">
    <div class="row justify-content-center">
      <div class="col-lg-4">
        <div class="card contact-card rounded-4">
          <div class="card-body mt-4">
            <div class="fw-bold text-black text-center fs-4 mb-5">
              Kapcsolat
            </div>
            <div class="alert alert-success" role="alert" v-if="success">
              Az üzenetet sikeresen elküldtük!
            </div>
            <div
              class="d-flex justify-content-center align-items-center"
              v-if="loading"
            >
              <div
                class="spinner-border text-primary spinner-border-sm"
                role="status"
              >
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
            <div class="mb-3">
              <label for="" class="form-label">Név*</label>
              <input
                type="text"
                class="form-control"
                aria-describedby="helpId"
                placeholder="Példa Péter"
                v-model="form.name"
              />
            </div>
            <div class="mb-3">
              <label for="" class="form-label">E-mail cím*</label>
              <input
                type="text"
                class="form-control"
                aria-describedby="helpId"
                placeholder="pelda@pelda.hu"
                v-model="form.email"
              />
            </div>
            <div class="mb-3">
              <label for="" class="form-label">Üzenet*</label>
              <textarea
                class="form-control"
                cols="30"
                rows="5"
                v-model="form.message"
              ></textarea>
            </div>
            <div class="text-center">
              <button
                class="btn btn-primary bg-black border-0 px-5 rounded-4"
                @click="sendEmail"
                :disabled="
                  form.email === '' ||
                  form.name === '' ||
                  form.message === '' ||
                  loading
                "
              >
                Küldés
                <i class="bi bi-send"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-1"></div>
      <div class="col-lg-4 my-4">
        <div class="fs-3 fw-bold text-black">
          Építőipari szolgáltatásokért forduljon hozzánk
        </div>
        <hr class="text-black border-2" />
        <p class="lead text-black">
          A Pro-P Capital egy vízszerelésre, festésre, épületek felújítására,
          tetőszerkezet javítására, épület takarításra és villanyszerelésre
          szakosodott építőipari cég. Tapasztalt csapatunk minőségi kivitelezést
          és a részletekre való odafigyelést biztosít, így biztosítva az
          ügyfelek elégedettségét. Forduljon hozzánk még ma ingyenes
          konzultációért és becslésért.
        </p>
        <div class="row my-2" v-for="(helper, index) in helpers" :key="index">
          <div class="col-2">
            <div class="contact-icon-circle">
              <i :class="`bi ${helper.icon} contact-icon fs-5`"></i>
            </div>
          </div>
          <div class="col-10 my-auto text-black">
            <div class="fs-6 text-black">
              <a
                :href="
                  helper.icon === 'bi-telephone'
                    ? `tel:${helper.text}`
                    : helper.icon == 'bi-envelope'
                    ? `mailto:${helper.text}`
                    : `#contact`
                "
                style="
                  text-transform: none !important;
                  color: black !important;
                  text-decoration: none !important;
                "
                >{{ helper.text }}</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
import { helpers } from "../helpers/fiftHelper";
import axios from "axios";
export default {
  name: "FifthSection",
  setup() {
    const form = ref({
      name: "",
      email: "",
      message: "",
    });
    const loading = ref(false);
    const success = ref(false);
    const sendEmail = async () => {
      loading.value = true;
      try {
        await axios.post("https://api.emailjs.com/api/v1.0/email/send", {
          service_id: "service_2yesmd5",
          template_id: "template_w8rkpwn",
          user_id: "rxCYeA0nhbUUGLPl4",
          template_params: {
            name: form.value.name,
            email: form.value.email,
            message: form.value.message,
          },
        });
        success.value = true;
        setTimeout(() => {
          success.value = false;
        }, 5000);
      } catch (error) {
        console.log(error);
      } finally {
        loading.value = false;
        form.value.name = "";
        form.value.email = "";
        form.value.message = "";
      }
    };
    return {
      helpers,
      form,
      sendEmail,
      success,
      loading,
    };
  },
};
</script>
<style>
.contact-card {
  background-color: #f5f5f5 !important;
  border: none !important;
}
.contact-icon-circle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background-color: #fc4b371f;
  border-radius: 50%;
  color: #ffffff;
}
.contact-icon {
  color: #fc4b37;
}
</style>
