<template>
  <div class="container-fluid bg-black py-4">
    <div class="row justify-content-center">
      <div class="col-6 text-center">
        ©{{ year }} Pro-P Capital Kft. Minden jog fenntartva
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "SixthSection",
  setup() {
    const year = new Date().getFullYear();
    return {
      year,
    };
  },
};
</script>
