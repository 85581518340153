<template>
  <div class="p-5 text-center bg-image header-bg">
    <div class="container">
      <div class="row mt-5">
        <div class="col-lg-5">
          <h1 class="display-5 fw-bold lh-1 mb-3 text-start text-white">
            Építőipari szolgáltatások
          </h1>
          <p class="lead mt-3 text-white text-start">
            Cégünk magas színvonalú építési <br />és felújítási szolgáltatások
            nyújtására specializálódott építőipari cég.
          </p>
          <div class="d-grid gap-2 d-md-flex justify-content-md-start">
            <a
              class="btn btn-primary rounded-5 p-3 bg-white text-black border-0 fw-bold"
              href="#contact"
            >
              Vegye fel a kapcsolatot <br />
              velünk még ma!
              <i class="bi bi-mailbox-flag fs-4"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "HeroComponent",
};
</script>
<style>
.header-bg {
  background-image: url("../assets/test-background.jpg");
  height: 500px; /* You must set a specified height */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
  box-shadow: inset 6rem 5rem 8rem 2rem black;
  margin-bottom: 10em;
}
</style>
