export const texts = [
  {
    name: "S. András",
    text: "A Pro-P Capital csodálatos! Kiváló vízszerelési szolgáltatásokat nyújtottak az új otthonomban. Nagyon ajánlott.",
    image: "k-janos",
  },
  {
    name: "K. János",
    text: "A Pro-P Capital festői kiváló munkát végeztek az irodaterületem kifestésében. Lenyűgözött a részletekre való odafigyelésük és professzionalizmusuk.",
    image: "s-andras",
  },
  {
    name: "H. Evelin",
    text: "Fel sem ismeretem az irodát, miután a Pro-P Capital végzett az takarítással. Ráadásul nagyon gyorsan. Ajánlom őket!",
    image: "h-evelin",
  },
];
