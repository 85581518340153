<template>
  <div class="container" id="second-section">
    <div class="row justify-content-center">
      <div class="col-md-8">
        <div class="card rounded-4" id="second-section-card">
          <div class="row justify-content-between g-0">
            <div class="col-lg-5 my-auto ps-4">
              <div class="card-body text-center text-lg-start">
                <h3 class="card-title fw-bold">
                  Kiemelt jellemző: <br />Minőségi kivitelezés
                </h3>
                <p class="card-text">
                  Csapatunk büszke arra, hogy minőségi kivitelezést és vevői
                  elégedettséget biztosít.
                </p>
                <div class="d-grid gap-2 d-lg-flex justify-content-lg-start">
                  <a
                    class="btn btn-primary rounded-5 p-3 bg-black text-white border-0 fw-bold"
                    href="#contact"
                  >
                    Vegye fel a kapcsolatot <br />
                    velünk még ma!
                    <i class="bi bi-mailbox-flag fs-4"></i>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <img
                src="../assets/quality-work.jpg"
                class="img-fluid rounded-4"
                alt="..."
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "SecondSection",
};
</script>
<style>
#second-section {
  margin-bottom: 3em;
}
#second-section-card {
  background-color: #f5f5f5;
  border: 2px black solid !important;
}
</style>
