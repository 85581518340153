<template>
  <section class="my-4" id="first-section">
    <div class="container">
      <div class="row justify-content-center">
        <div
          :class="[
            'col-lg-4',
            'my-auto',
            'text-black',
            'hoverable',
            { 'border-end': index !== columns.length - 1 },
          ]"
          v-for="(column, index) in columns"
          :key="index"
          id="first-section-column"
        >
          <h4 class="heading-5 fw-bold ms-3 my-4">{{ column.heading }}</h4>
          <p class="ms-3">
            {{ column.text }}
          </p>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { columns } from "../helpers/firstSectionText";
export default {
  name: "FirstSection",
  setup() {
    return { columns };
  },
};
</script>
<style>
#first-section {
  padding-bottom: 4rem;
}
.hoverable {
  transition: all 0.3s ease-in-out;
}
.hoverable:hover {
  cursor: pointer;
  scale: 1.02;
}
@media (max-width: 992px) {
  #first-section-column {
    border-bottom: 1px solid #e0e0e0;
  }
}
</style>
