<template>
  <HeaderComponent />
  <HeroComponent />
  <FirstSection />
  <SecondSection />
  <ThirdSection />
  <FourthSection />
  <FifthSection />
  <SixthSection />
</template>

<script>
import HeaderComponent from "@/components/HeaderComponent.vue";
import HeroComponent from "@/components/HeroComponent.vue";
import FirstSection from "@/components/FirstSection.vue";
import SecondSection from "@/components/SecondSection.vue";
import ThirdSection from "@/components/ThirdSection.vue";
import FourthSection from "@/components/FourthSection.vue";
import FifthSection from "@/components/FifthSection.vue";
import SixthSection from "@/components/SixthSection.vue";
export default {
  name: "HomeView",
  components: {
    HeaderComponent,
    HeroComponent,
    FirstSection,
    SecondSection,
    ThirdSection,
    FourthSection,
    FifthSection,
    SixthSection,
  },
};
</script>
