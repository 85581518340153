<template>
  <div class="container" id="services">
    <div class="row justify-content-center services-title">
      <div class="col-lg-5 text-center">
        <h3 class="display-4 text-black fw-bold">Szolgáltatásaink</h3>
        <hr class="text-secondary" />
      </div>
    </div>
    <div class="row justify-content-center">
      <div
        class="col-lg-4 mb-4 fs-1 text-black"
        v-for="(service, index) in services"
        :key="index"
      >
        <div
          class="card service-card rounded-4"
          @mouseenter="test($event)"
          @mouseleave="test2($event)"
        >
          <div class="card-body">
            <h4 class="card-title">
              <div class="icon-circle">
                <i :class="`bi ${service.icon} service-icon`"></i>
              </div>
            </h4>
            <h4 class="card-title mb-5">
              {{ service.heading }}
            </h4>
            <p class="lead w-60 mb-4">{{ service.text }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { services } from "../helpers/thirdSectionServices";
export default {
  name: "ThirdSection",
  setup() {
    const test = (e) => {
      const element = e.target.children[0].children[0].children[0];
      element.classList.add("icon-spin");
    };
    const test2 = (e) => {
      const element = e.target.children[0].children[0].children[0];
      element.classList.remove("icon-spin");
    };
    return {
      services,
      test,
      test2,
    };
  },
};
</script>
<style>
@keyframes spin {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(359deg);
  }
}
.service-card {
  background-color: #f5f5f5 !important;
  border: none !important;
  transform-style: preserve-3d;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.service-card:hover {
  transform: scale(1.05);
  transition: all 0.3s ease-in-out;
}
.icon-spin {
  animation: spin 0.4s linear;
}
.icon-circle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background-color: #fc4b371f;
  border-radius: 50%;
  color: #ffffff;
}
.service-icon {
  color: #fc4b37;
}
.services-title {
  margin-top: 100px;
  margin-bottom: 100px;
}
#services {
  margin-bottom: 8rem;
}
</style>
