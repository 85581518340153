<template>
  <div class="container-fluid bg-black py-5" id="testemonies">
    <div class="row justify-content-center mb-5">
      <div class="col-lg-5 text-center">
        <h3 class="display-4 fw-bold">Ügyfélbeszámolók</h3>
        <hr class="text-white" />
        <p class="lead my-4">
          Ügyfeleink szeretik építőipari szolgáltatásainkat. Olvassa el, mit
          mondanak a velünk végzett munka során szerzett tapasztalataikról.
        </p>
      </div>
    </div>
    <div class="row justify-content-center mb-5">
      <div
        class="col-lg-4 my-4"
        v-for="(customer, index) in texts"
        :key="index"
      >
        <div class="card customer-card rounded-4 p-4">
          <div class="card-body">
            <p class="card-text text-white">{{ customer.text }}</p>
            <div class="row">
              <div class="col-lg-2 col-3">
                <img
                  :src="require(`../assets/${customer.image}.jpg`)"
                  class="rounded-circle shadow-1-strong"
                  width="60"
                  height="60"
                />
              </div>
              <div class="col-lg-3 col-4">
                <div class="text-white">{{ customer.name }}</div>
                <i
                  class="bi bi-star-fill text-warning"
                  v-for="index in 5"
                  :key="index"
                ></i>
              </div>
              <div class="col-5 text-end">
                <i class="bi bi-quote text-white fs-1"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { texts } from "../helpers/fourhtSectionText";
export default {
  name: "FourthSection",
  setup() {
    return {
      texts,
    };
  },
};
</script>
<style>
.section-heading {
  color: #fff;
}
.customer-card {
  background-color: #222629ff !important;
}
#testemonies {
  margin-bottom: 5rem;
}
</style>
