export const services = [
  {
    icon: "bi-droplet",
    heading: "Vízszerelés",
    text: "Bízza ránk, hogy eligazodunk a csövek és szerelvények bonyolult kérdéseiben, zökkenőmentes megoldásokat kínálva.",
  },
  {
    icon: "bi-brush",
    heading: "Festés",
    text: "Professzionális festési szolgáltatást nyújtunk lakó- és kereskedelmi ingatlanok számára.",
  },
  {
    icon: "bi-lightning-charge",
    heading: "Villanyszerelés",
    text: "Csapatunk villanyszerelésre és vezetékezésre szakosodott új építésnél és felújításnál egyaránt.",
  },
  {
    icon: "bi-building-up",
    heading: "Felújítás",
    text: "A kisebb felújításoktól a teljes épületátalakításokig bármilyen teret átalakíthatunk.",
  },
  {
    icon: "bi-layers",
    heading: "Egyedi kivitelezés",
    text: "Egyedi projekteket tervezünk és készítünk az Ön egyedi igényeinek és preferenciáinak megfelelően.",
  },
  {
    icon: "bi-bucket",
    heading: "Takarítás",
    text: "Aprólékosan az Ön egyedi igényeinek és preferenciáinak megfelelően takarítjuk ki bármely társasházát vagy irodáját.",
  },
];
