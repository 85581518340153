<template>
  <nav class="navbar navbar-expand-lg bg-black sticky-top py-3">
    <div class="container">
      <a class="navbar-brand text-white fw-bold" href="#">Pro-P Capital Kft.</a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#navbarOffcanvasLg"
        aria-controls="navbarNavAltMarkup"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div
        class="offcanvas offcanvas-end text-white bg-black"
        tabindex="-1"
        id="navbarOffcanvasLg"
        aria-labelledby="navbarOffcanvasLgLabel"
      >
        <div class="offcanvas-header">
          <button
            type="button"
            class="btn-close btn-close-white"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div class="offcanvas-body text-white justify-content-end">
          <div class="navbar-nav text-white" id="header-link">
            <a class="nav-link mx-1" href="#services">Szolgáltatásaink</a>
            <a class="nav-link mx-1" href="#testemonies">Ügyfélbeszámolók</a>
            <a class="nav-link mx-1" href="#contact">Kapcsolat</a>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>
<script>
export default {
  name: "HeaderComponent",
};
</script>
<style>
#navbarNavAltMarkup .nav-link {
  color: white;
}
#header-link .nav-link {
  color: white !important;
}
</style>
