export const columns = [
  {
    heading: "Tapasztalt csapat",
    text: "Tapasztalt szakembergárdánk minden típusú építési projekt lebonyolítására szakértelemmel rendelkezik.",
  },
  {
    heading: "Szolgáltatások széles skálája",
    text: "A vízszereléstől és festéstől a tetőjavításig és villanyszerelésig széles körű szolgáltatást nyújtunk.",
  },
  {
    heading: "Figyelem a részletekre",
    text: "Minden részletre nagy figyelmet fordítunk, hogy projektjeink a legmagasabb színvonalon valósuljanak meg.",
  },
];
